"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.WITHDRAWN = exports.OTHER_COUNTRY = exports.NO_PURCHASE = exports.NOTGIVEN = exports.CONSENT_PURPOSES = exports.CONFIRMED = exports.COMPLETE_PURCHASE = void 0;
// TODO add purpose ids for qa and stage, add general and no nuk purpose in prod
var CONSENT_PURPOSES = {
  local: {
    marketing: '1a0c07f3-de87-418b-b6e0-9cd5386aff76',
    marketingGeneral: '3d462846-26bd-41a4-8ed4-3e8cc20a6e1b',
    marketingNonUk: 'e0e8184c-2bec-4031-9656-99d412ddcae9',
    termsOfSale: '542de212-755e-459c-a086-eea977d8112b',
    privacyNotice: '6d370046-07c1-4f38-a353-93b25724d310'
  },
  dev: {
    marketing: '1a0c07f3-de87-418b-b6e0-9cd5386aff76',
    marketingGeneral: '3d462846-26bd-41a4-8ed4-3e8cc20a6e1b',
    marketingNonUk: 'e0e8184c-2bec-4031-9656-99d412ddcae9',
    termsOfSale: '542de212-755e-459c-a086-eea977d8112b',
    privacyNotice: '6d370046-07c1-4f38-a353-93b25724d310'
  },
  qa: {
    marketing: '72b17b17-3e10-41ea-a708-c03f93d17cbc',
    marketingGeneral: '87a19b69-0150-4985-8223-a12662ecb346',
    marketingNonUk: '73a9da03-4d94-44bb-bebb-3d99a69d8c9e',
    termsOfSale: 'b03e7ed2-fb7d-4d61-a849-d36806fa2bd1',
    privacyNotice: '4eb850a6-ba13-44dd-96e5-8122fb2512f6'
  },
  stg: {
    marketing: '7c92ccb4-751d-44f3-9317-d2c70cae83d9',
    marketingGeneral: 'a9d5b324-aea7-421c-b073-e5a3ac7c835b',
    marketingNonUk: '3c695b9c-b92b-4c5a-9bda-d2129a21edaf',
    termsOfSale: '8da67eda-926e-44be-b310-3d1f8ec98abf',
    privacyNotice: '1cce2557-2f4d-4955-9749-92d932dce314'
  },
  prod: {
    marketing: '552cb063-54e5-4e32-95b2-c45a25d039ca',
    marketingGeneral: '79d8d6f1-7c00-4ca3-836b-7f10cd6dee1e',
    marketingNonUk: '8e050118-5772-429b-a8a6-5349cc1d6d1e',
    termsOfSale: '71959768-6f83-43c3-90d5-a538cc3053d4',
    privacyNotice: '4b6d85a0-4252-49ea-893e-18295c68630f'
  }
};
exports.CONSENT_PURPOSES = CONSENT_PURPOSES;
var COMPLETE_PURCHASE = 'COMPLETE_PURCHASE';
exports.COMPLETE_PURCHASE = COMPLETE_PURCHASE;
var NO_PURCHASE = 'NO_PURCHASE';
exports.NO_PURCHASE = NO_PURCHASE;
var OTHER_COUNTRY = 'OTHER_COUNTRY';
exports.OTHER_COUNTRY = OTHER_COUNTRY;
var CONFIRMED = 'CONFIRMED';
exports.CONFIRMED = CONFIRMED;
var NOTGIVEN = 'NOTGIVEN';
exports.NOTGIVEN = NOTGIVEN;
var WITHDRAWN = 'WITHDRAWN';
exports.WITHDRAWN = WITHDRAWN;