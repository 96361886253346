"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "COMPLETE_PURCHASE", {
  enumerable: true,
  get: function get() {
    return _constants.COMPLETE_PURCHASE;
  }
});
Object.defineProperty(exports, "CONFIRMED", {
  enumerable: true,
  get: function get() {
    return _constants.CONFIRMED;
  }
});
Object.defineProperty(exports, "CONSENT_PURPOSES", {
  enumerable: true,
  get: function get() {
    return _constants.CONSENT_PURPOSES;
  }
});
Object.defineProperty(exports, "NOTGIVEN", {
  enumerable: true,
  get: function get() {
    return _constants.NOTGIVEN;
  }
});
Object.defineProperty(exports, "NO_PURCHASE", {
  enumerable: true,
  get: function get() {
    return _constants.NO_PURCHASE;
  }
});
Object.defineProperty(exports, "OTHER_COUNTRY", {
  enumerable: true,
  get: function get() {
    return _constants.OTHER_COUNTRY;
  }
});
Object.defineProperty(exports, "fetchMarketingConsentIds", {
  enumerable: true,
  get: function get() {
    return _api.fetchMarketingConsentIds;
  }
});
Object.defineProperty(exports, "getConsentPurposes", {
  enumerable: true,
  get: function get() {
    return _api.getConsentPurposes;
  }
});
Object.defineProperty(exports, "getConsents", {
  enumerable: true,
  get: function get() {
    return _api.getConsents;
  }
});
Object.defineProperty(exports, "postConsents", {
  enumerable: true,
  get: function get() {
    return _api.postConsents;
  }
});
var _api = require("./api");
var _constants = require("./constants");