export default async function postConsent(collectionPoint, consentPurposes, userId) {
  const response = await fetch('/api/consent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ collectionPoint, consentPurposes, userId }),
  });

  if (!response.ok) {
    throw new Error('Unable to post consent');
  }

  return response.json();
}
